<template>
    <v-list>
        <template v-for="consegna in consegne">
            <ConsegnaEl :consegna="consegna" :key="consegna.id"/>
            <v-divider :key="consegna.id + '-div'"/>
        </template>
    </v-list>
</template>

<script>
import ConsegnaEl from "./consegna_mobile_element.vue"
export default {
    components:{
        ConsegnaEl
    },
    computed:{
        consegne() {
      return this.$store.getters.get_consegne;
    },
    }
}
</script>