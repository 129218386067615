<template>
  <v-row>
    <v-col cols="12">
      <ConsegneAttive/>
    </v-col>
    <v-col cols="12">
      <ConsegneArchivio/>
    </v-col>
  </v-row>
</template>

<script>
import ConsegneAttive from "./consegne_attive.vue"
import ConsegneArchivio from "./consegne_archivio.vue"
export default {
  components: {
    ConsegneAttive,
    ConsegneArchivio
  },
};
</script>

