<template>
  <v-skeleton-loader
    v-if="loading"
    type="list-item-three-line"
  ></v-skeleton-loader>
  <v-list-item v-else>
    <v-list-item-content>
      <v-list-item-title>
        {{ n_scarichi }} Scarichi
        <v-icon class="ml-2" @click="show_cli = !show_cli">{{
          show_cli ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon></v-list-item-title
      >
      <template v-if="show_cli">
        <v-list-item-subtitle
          v-for="(tappa, index) in consegna.tappe.filter(
            (tappa) => !tappa.ritorno
          )"
          :key="tappa.id"
        >
          {{ index + 1 }} - {{ cliente(tappa.cliente).nome }}
        </v-list-item-subtitle>
        <v-divider />
      </template>
      <v-list-item-subtitle>
        Stato:
        <template v-if="consegna.stato == 0">
          <span class="orange--text">Programmata</span>
        </template>
        <template v-else-if="consegna.stato == 1">
          <span class="indigo--text">In Consegna</span>
        </template>
        <template v-else-if="consegna.stato == 2">
          <span class="green--text">Competata</span>
        </template>
        <template v-else-if="consegna.stato == 3">
          <span class="purple--text">In Attesa</span>
        </template>
      </v-list-item-subtitle>
      
      <v-list-item-subtitle> Tot CC: {{ tot_cc_carico }} </v-list-item-subtitle>
      <v-list-item-subtitle>
        Durata: {{ tempo_humanized }}
      </v-list-item-subtitle>
      <!--<v-list-item-subtitle>
        Partenza: {{ partenza_prevista }}
      </v-list-item-subtitle> -->
    </v-list-item-content>
    <!-- <v-list-item-action>
      <v-dialog
        v-model="dialog_archivia"
        v-if="consegna.stato == 2 && !consegna.archiviata"
        width="400"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon color="purple" v-on="on">
            <v-icon>mdi-package-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="purple--text"> Archivia Consegna </v-card-title>
          <v-card-text> La consegna verrà archiviata. </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="purple" @click="archivia_consegna()">
              <v-icon class="mr-2">mdi-package-down</v-icon>
              Archivia
            </v-btn>
            <v-btn text color="grey" @click="dialog_archivia = false"
              >Annulla</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        v-if="consegna.archiviata"
        icon
        color="green"
        @click="attiva_consegna()"
      >
        <v-icon>mdi-recycle</v-icon>
      </v-btn>

      <v-dialog v-model="dialog_avvia" v-if="consegna.stato == 0" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon color="green" v-on="on">
            <v-icon>mdi-truck-delivery</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="green--text"> Avvia Consegna </v-card-title>
          <v-card-text> La consegna verrà avviata. </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="green" @click="avvia_consegna()">
              <v-icon class="mr-2">mdi-truck-delivery</v-icon>
              Avvia
            </v-btn>
            <v-btn text color="grey" @click="dialog_avvia = false"
              >Annulla</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_termina" v-if="consegna.stato == 1" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon color="amber" v-on="on">
            <v-icon>mdi-truck-check</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="amber--text"> Termina Consegna </v-card-title>
          <v-card-text> La consegna verrà terminata. </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="green" @click="termina_consegna()">
              <v-icon class="mr-2">mdi-truck-chek</v-icon>
              Termina
            </v-btn>
            <v-btn text color="grey" @click="dialog_termina = false"
              >Annulla</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action> -->
  </v-list-item>
</template>

<script>
import cons_element_mixin from "../mixin/consegna_element_mixin";
export default {
  mixins: [cons_element_mixin],
  data() {
    return {
      show_cli: false,
    };
  },
};
</script>