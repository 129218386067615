<template>
  <div>
    <v-select
      outlined
      hide-details
      dense
      rounded
      class="ml-4 mt-4"
      :items="pariodo_list"
      v-model="periodo"
      item-text="label"
      item-value="value"
    ></v-select>
    <v-list>
        <template v-for="consegna in sorted">
            <ConsegnaEl :consegna="consegna" :key="consegna.id"/>
            <v-divider :key="consegna.id + '-div'"/>
        </template>
    </v-list>
  </div>
</template>

<script>
import consegne_evase_mixin from "../mixin/consegne_evase_mixin";
import ConsegnaEl from "./consegna_mobile_element.vue"

export default {
    components:{
        ConsegnaEl
    },
  mixins: [consegne_evase_mixin],
};
</script>