import moment from "moment";
export default {
  data() {
    return {
      periodo: 7,
      pariodo_list: [
        { label: "Ultimo Giorno", value: 1 },
        { label: "Ultima Settimana", value: 7 },
        { label: "Ultimo Mese", value: 31 },
        { label: "Ultimo Anno", value: 365 },
      ],
      sort_up: false,
    };
  },
  watch: {
    periodo: function () {
      this.$store.dispatch("fetch_consegne_archivio", {
        ts_inizio: moment().subtract(this.periodo, "days").format("X"),
        ts_fine: moment().format("X"),
      });
    },
  },
  computed: {
    consegne() {
      return this.$store.getters.get_consegne_archivio;
    },
    sorted() {
      if (this.sort_up) {
        return this.consegne
          .slice()
          .sort((a, b) => b.ts_creazione - a.ts_creazione);
      } else {
        return this.consegne;
      }
    },
  },
};
