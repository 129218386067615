<template>
    <v-tabs color="green">
        <v-tab>Attive</v-tab>
        <v-tab>Evase</v-tab>
        <v-tab-item>
            <ConsegneAttive/>
        </v-tab-item>
        <v-tab-item>
            <ConsegneEvase/>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import ConsegneAttive from "./consegne_attive_mobile.vue"
import ConsegneEvase from "./consegne_evase_mobile.vue"
export default {
    components:{
        ConsegneAttive,
        ConsegneEvase
    }
}
</script>