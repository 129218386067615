<template>
  <ConsegneDesktop v-if="!$vuetify.breakpoint.mobile" />
  <ConsegneMobile v-else/>
</template>

<script>
import ConsegneDesktop from "@/components/Consegne/desktop/consegne_desktop.vue"
import ConsegneMobile from "@/components/Consegne/mobile/consegne_main_mobile.vue"
export default {
  components: {
    ConsegneDesktop,
    ConsegneMobile
  },
};
</script>