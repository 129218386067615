<template>
  <v-card :height="height">
    <v-card-title class="grey--text text-subtitle-1">
      Consegne Archivio
      <v-icon @click="expand()" class="ml-4">{{
        is_expanded ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
      <v-select
        outlined
        hide-details
        dense
        rounded
        class="ml-4"
        :items="pariodo_list"
        v-model="periodo"
        item-text="label"
        item-value="value"
      ></v-select>
      <v-spacer />
    </v-card-title>

    <v-card-text :class="is_expanded ? 'scroll_extended' : 'scroll'">
      <v-divider class="mb-2" />
      <v-row class="font-weight-medium">
        <v-col cols="1" class="d-flex justify-center"> # </v-col>
        <v-col cols="2"> Clienti </v-col>
        <v-col cols="2">
          Data
          <v-icon class="ml-2" color="grey" @click="sort_up = !sort_up">{{
            sort_up ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-col>

        <v-col cols="2" class="d-flex justify-center"> Stato </v-col>
        <v-col cols="1"> Carrelli </v-col>
        <v-col cols="2"> Durata </v-col>
      </v-row>
      <v-divider class="my-2" />

      <ConsegnaEl
        v-for="consegna in sorted"
        :consegna="consegna"
        :key="consegna.id"
      />
      <v-row class="py-8" v-if="!consegne.length" justify="center">
        Nessuna Consegna in Archivio
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import ConsegnaEl from "./consegna_element_desktop copy.vue";
import consegne_evase_mixin from "../mixin/consegne_evase_mixin";
export default {
  components: {
    ConsegnaEl,
  },
  mixins: [consegne_evase_mixin],
  data() {
    return {
      is_expanded: false,
      height: 400,
    };
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 350;
      } else {
        this.is_expanded = true;
        this.height = 700;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 250px;
  overflow-y: scroll;
}
.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>
